// import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import CatalogueLayout from 'src/Layouts/Catalogue'

const CataloguePage = () => {

    return (
        <div className='w-full'>
            <CatalogueLayout />
            <ToastContainer
                position="bottom-center"
                newestOnTop limit={1} />
        </div>
    )
}

export default CataloguePage