import React, { useEffect, useState } from 'react';
import ReactPaginationStyle from 'src/Components/ReactPagination';
import SelectRows from 'src/Components/SelectRows';
import CardLoader from 'src/Components/CardLoader';
import { GET_ATTCHMENT_DETAILS } from 'src/Services/Redux/Actions/orders';
import { useDispatch, useSelector } from 'react-redux';


export default function OrdersContent({ hooks }) {
    const {
        data,
        totalRecords,
        selectedRowDetails,
        setSelectedRowDetails,
        setImageData,
        setImage_UniqueID,
        page,
        handleRows,
        rows,
        loader,
        handlePagination,
    } = hooks;

    const dispatch = useDispatch();
    const carts = useSelector(state => state?.cartReducer?.carts);
    const [rowSelection, setRowSelection] = useState({});

    useEffect(() => {
        const selectedAre = {};
        for (const id of carts?.ids || []) {  // Handle the case where carts.ids is null or undefined
            selectedAre[id] = true;
        }
        setRowSelection(selectedAre);
    }, [carts]); // Add carts as a dependency



    const getSelectedRowDetail = (row) => {
        const payload = {
            INVENTORYLOTID: row?.inventorylotid,
        };
        dispatch(GET_ATTCHMENT_DETAILS(payload));
        setSelectedRowDetails(row);
        setImageData(row?.Image_URL);
        setImage_UniqueID(row?.Image_UniqueID);
    };

    const startIndex = (page - 1) * rows;
    const endIndex = Math.min(startIndex + rows, data.length);
    const currentPageData = data.slice(startIndex, endIndex);

    // const handleCardClick = (row) => {
    //     getSelectedRowDetail(row);
    //     // Toggle row selection
    //     setRowSelection((prevSelection) => ({
    //         ...prevSelection,
    //         [row.inventorylotid]: !prevSelection[row.inventorylotid],
    //     }));
    //     console.log(`Card Clicked`)
    //     console.table(row)
    // };

    // useEffect(() => {
    //     let checkedItems = Object.keys(rowSelection)
    //     if (checkedItems?.length) {
    //         let allCartIds = carts?.ids
    //         let cartlist = carts?.data

    //         for (let rowKey of checkedItems) {
    //             if (!allCartIds?.includes(rowKey)) {
    //                 let newItem = data?.find(item => item.inventorylotid === rowKey)
    //                 allCartIds.push(rowKey)
    //                 cartlist.push({ ...newItem, cartfrom: 'ORDER' })
    //             } else {
    //                 // AlertInfo('Item is already in cart')
    //             }

    //         }
    //         // dispatch({
    //         //     type: ACTION_HANLDE_CART_UPDATE, data: {
    //         //         data: cartlist,
    //         //         ids: allCartIds
    //         //     }
    //         // })
    //     }
    // }, [rowSelection])

    return (
        <>
            {loader ? (
                <CardLoader />
            ) : (
                <div>
                    <div className="flex justify-start items-center space-x-4">
                        <ReactPaginationStyle
                            total={totalRecords}
                            currentPage={page}
                            handlePagination={handlePagination}
                            limit={rows}
                        />
                        <SelectRows rows={rows} setRows={handleRows} />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-4">
                        {currentPageData.map((row) => (
                            <div
                                key={row.inventorylotid}
                                className={`border rounded-xl p-4 cursor-pointer ${rowSelection[row.inventorylotid] ? 'bg-gray-100' : 'bg-white'}`} // Use a suitable hover color
                                onClick={() => getSelectedRowDetail(row)} // Call getSelectedRowDetail on click
                            >
                                <img
                                    src={row?.Primary_Image_URL || 'placeholder_image.jpg'}
                                    alt={row.itemnumber}
                                    className="w-full h-48 object-cover rounded-md mb-2"
                                />

                                <div className="font-bold">{row.itemnumber}</div>
                                <div>Style: {row.Style}</div>
                                <div>Price: ${row.Retail_Price}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}