
// 

import InputField from "src/Components/InputField";
import 'react-datepicker/dist/react-datepicker.css';

const UserAndShipmentDetails = ({
    errors,
    register,
    setValue,
    getValues
}) => {
    return (
        <div className="w-full shadow rounded-md mt-3 p-2 bg-white">
            <h4 className="my-1 font-[600]">Shipment Details</h4>
            <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-1">
                <div>
                    <label className="text-sm">Ship Account <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.ShippingAccount || ''}
                        setValue={setValue}
                        maxLength={50}
                        errorMsg='Shipping Account is required !'
                        required={true}
                        name='ShippingAccount'
                        register={register}
                        errors={errors}
                        type='input'
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">Name <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <InputField
                        value={getValues?.Name}
                        setValue={setValue}
                        maxLength={30}
                        errorMsg='Name is required !'
                        required={true}
                        name='Name'
                        register={register}
                        errors={errors}
                        type='input'
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">Phone <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.Phone}
                        setValue={setValue}
                        errorMsg='Phone is required !'
                        required={true}
                        name='Phone'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">Email <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.EmailEntered}
                        setValue={setValue} type='email'
                        maxLength={60}
                        errorMsg='Email is required !'
                        required={true}
                        name='EmailEntered'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
            </div>
            <hr className="my-4" />
            <h4 className="my-1 font-[600]">Delivery Details</h4>
            {/* <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1"> */}
            <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-6 md:grid-cols-2 sm:grid-cols-1"> 
                <div>
                    <label className="text-sm">Address 1 <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.Address1}
                        setValue={setValue}
                        maxLength={80}
                        errorMsg='Address1 is required !'
                        required={true}
                        name='Address1'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">Address 2  </label>
                    <br />
                    <InputField
                        value={getValues?.Address2}
                        setValue={setValue}
                        maxLength={80}
                        errorMsg='Address2 is not required !'
                        required={false}
                        name='Address2'
                        register={register}
                        errors={errors}
                        className="border mt-1 w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">City <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.City}
                        setValue={setValue}
                        maxLength={20}
                        errorMsg='City is required !'
                        required={true}
                        name='City'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">State <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.State}
                        setValue={setValue}
                        maxLength={20}
                        errorMsg='State is required !'
                        required={true}
                        name='State'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>

                <div>
                    <label className="text-sm">Zip Code <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.Zip_Code}
                        setValue={setValue}
                        maxLength={10}
                        errorMsg='Zip Code is required !'
                        required={true}
                        name='Zip_Code'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
            </div>
            <hr className="my-4" />
            <h4 className="my-1 font-[600]">Other Details</h4>
            <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-6 md:grid-cols-2 sm:grid-cols-1">
                <div>
                    <label className="text-sm">Customer PO <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.CustomerPO}
                        setValue={setValue}
                        maxLength={20}
                        errorMsg='Customer PO is required !'
                        required={true}
                        name='CustomerPO'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " />
                </div>
                <div>
                    <label className="text-sm">Order Type <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <select
                        {...register('OrderType', {
                            value: "",
                            required: true
                        })}

                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-2 rounded-md " >
                        <option value="" disabled>Select an Option</option>
                        <option value="Confirmed Order">Confirmed Order</option>
                        <option value="Quote Order">Quote Order</option>
                    </select>
                    {errors['OrderType'] && <p className="text-red-400 text-sm">Order Type is required !</p>}
                </div>
                <div>
                    <label className="text-sm">Due Date <span className="text-red-400 font-medium text-lg ">*</span> </label>
                    <br />
                    <InputField
                        value={getValues?.DeliveryDate}
                        setValue={setValue}
                        min={new Date().toISOString().split('T')[0]}
                        type='date'
                        errorMsg='Delivery Date is required !'
                        required={true}
                        name='DeliveryDate'
                        register={register}
                        errors={errors}
                        className="text-sm border w-full focus:ring-0 outline-none  placeholder:text-gray-900 p-1.5 rounded-md " />
                </div>
            </div>
        </div>
    )
}
export default UserAndShipmentDetails;