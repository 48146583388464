import { SearchOutlined } from '@mui/icons-material';
import React from 'react';
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const DaysSelect = ({ column, setColumn, id, selectWidth }) => {
  const searchOptions = ['Select', 'Style', 'Category', 'Material', 'Primary Stone Shape'];

  const handleChange = (event) => {
    setColumn(event.target.value);
  };

  return (
    <div style={{ width: selectWidth }} className={`rounded-lg`}>
      <FormControl fullWidth>
        <Select
          id={id}
          value={column || 'Select'}
          label="Filter By Days"
          sx={{ height: '40px', border: 'none', "& fieldset": { border: 'none' } }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                height: 'auto',
                border: '0px'
              },
            },
          }}
          onChange={handleChange}
        >
          {searchOptions.map((option, index) => (
            <MenuItem key={index} value={option}> {option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


const SearchByColumn = ({ hooks }) => {
  let { fetchOrders,
    searchStr,
    setSearchStr,
    filterField,
    setFilterFiled, selectWidth } = hooks;

  const handleSearchTypeChange = (e) => {
    setSearchStr(e.target.value);
  };

  return (
    <div className=" max-w-96 flex justify-start items-center rounded-lg border bg-white">
      <DaysSelect
        selectWidth={selectWidth}
        column={filterField}
        setColumn={setFilterFiled}
        id='columnFIlter'
      />
      <input placeholder='Search...'
        value={searchStr}
        onChange={handleSearchTypeChange}
        className='w-[90%] focus:outline-0 focus:right-0 placeholder:font-[300]  p-2 ' />
      <IconButton onCanPlay={() => fetchOrders()} className='p-2'>
        <SearchOutlined color='action' fontSize='medium' />
      </IconButton>
    </div>
  );
};

export default SearchByColumn;

