import { ACTION_AJAFFE_JEWELLERY_DATA } from "src/Services/Constant/types"

// **  Initial State
const initialState = {
    jewelleryData: { list: [], pagination: {}}
}

const AjaffeJewelleryDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_AJAFFE_JEWELLERY_DATA:
            return {
                ...state,
                jewelleryData: action.data
            }
        default:
            return state
    }
}

export default AjaffeJewelleryDataReducer