import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import FileListing from "./filesListing";
import Notes from "./notes";
import RowDetails from "./rowDetails";

const ItemDetailTab = ({ selectedRow, handleChange, handleFileAdd, fileUploadLoader }) => {
    const [tabis, setTabis] = useState('detail')

    return <>
        <div className='min-h-40'>
            <Tabs
                value={tabis}
                onChange={(e, data) => setTabis(data)} aria-label="lab API tabs example">
                <Tab label="Details" value='detail' />
                <Tab label="Files" value='files' />
                <Tab label="Notes" value='notes' />
            </Tabs>

            {tabis === 'detail' && <RowDetails handleChange={handleChange} data={selectedRow} />}

            {tabis === 'files' && <FileListing fileUploadLoader={fileUploadLoader} handleFileAdd={handleFileAdd} data={selectedRow} />}

            {tabis === 'notes' && <Notes data={selectedRow} handleChange={handleChange}  />}

        </div>
    </>
}

export default ItemDetailTab;