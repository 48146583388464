import CatalogueTable from './CatalogueTable'
import TotalCart from 'src/Components/TotalCart';
import ReOrder from 'src/Components/ReOrder';
import { GET_AJAFFE_JEWELLERY_DATA } from 'src/Services/Redux/Actions/ajaffeJewelleryData'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import moment from 'moment';
import Button from '@mui/material/Button'
import SearchByColumn from './searchByColumn';
import FilterModal from './filterModal';
import 'react-datepicker/dist/react-datepicker.css';

const CatalogueLayout = () => {
    const dispatch = useDispatch()
    const [day, setDay] = useState(120)
    const [rows, setRows] = useState(10)
    const [page, setPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0)
    const [selectedRowDetails, setSelectedRowDetails] = useState(null);
    const [searchStr, setSearchStr] = useState('')
    const [filterField, setFilterFiled] = useState('')
    const [imageData, setImageData] = useState(null)
    const [image_UniqueID, setImage_UniqueID] = useState(null)
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const jewelleryOrders = useSelector(state => state?.AjaffeJewelleryDataReducer?.jewelleryData)

    useEffect(() => {
        console.log("DATA CHANGED:", jewelleryOrders);
        if(jewelleryOrders?.pagination?.totalRecords) {
            console.log("FOUND TOTAL RECORDS")
            setTotalRecords(jewelleryOrders?.pagination?.totalRecords)
        }
    }, [jewelleryOrders])

    useEffect(() => {
        console.log("TOTAL CATALOGUE RECORDS", totalRecords);
    },[totalRecords]);

    const [apiPayload, setApiPayload] = useState({
        start_date: moment().subtract(4, 'M').format('MM-DD-YYYY'),
        end_date: moment(new Date()).format('MM-DD-YYYY'),
        pageno: 1,
        limit: 10,
        searchStr: "",
        searchColumn: "",
        totalRecords,
        email: userinfo?.email,
    })

    const ui_to_db = {
        "Style": "Style",
        "Primary Stone Shape" : "Primary_Gemstone_Shape",
        "Material" : "Material",
        "Category" : "Category",
    }

    const fetchOrders = async () => {
        const formData = {
            requirement : 'Send Data',
            email: userinfo?.email,
            ...apiPayload,
            searchStr,
            searchColumn: ui_to_db[filterField]
        };
        await dispatch(GET_AJAFFE_JEWELLERY_DATA(formData))
        setLoader(false)
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    const handledate = (name, value) => {
        if(value){
            setApiPayload({
                ...apiPayload,
                totalRecords: 0,
                [name]: moment(value).format('MM-DD-YYYY')
            })
        }
    }

    const handleDays = async (day) => {
        setLoader(true)
        setDay(day)
        let updatedpayload = {
            pageno: 1,
            limit: 10,
            searchStr,
            searchColumn: filterField,
            totalRecords:0,
            email: userinfo?.email,
        }
        setApiPayload(updatedpayload)
        await dispatch(GET_AJAFFE_JEWELLERY_DATA(updatedpayload))
        setLoader(false)
    }

    const handlePagination = async (p) => {
        console.log("CATALOGUE PAGE", p)
        setPage(p)
        const data = {
            ...apiPayload,
            pageno: p || 1,
            totalRecords,
            email: userinfo?.email,
        };
        await dispatch(GET_AJAFFE_JEWELLERY_DATA(data))
        setApiPayload(data)
    }

    const handleRows = async (r) => {
        setRows(r)
        setLoader(true)
        const data = {
            ...apiPayload,
            limit: r,
            totalRecords,
            email: userinfo?.email,
        };
        await dispatch(GET_AJAFFE_JEWELLERY_DATA(data))
        setLoader(false)
        setApiPayload(data)
    }

    return (
        <div className='w-full'>
            <div className='w-full flex justify-between items-center my-2 pr-2 '>
                <h2 className='text-gray-800 font-[600] text-lg'>Catalogue</h2>
                <div className='flex justify-start items-center space-x-2'>
                    <ReOrder />
                    <TotalCart />
                </div>
            </div>
            <div className='my-2 w-full flex justify-between items-center '>
                {/* Below ui for large Screen */}
                <div className='hidden lg:block'>
                    <div id='filterTab' className='my-2 flex justify-start items-center space-x-2'>
                        <SearchByColumn
                            hooks={{
                                fetchOrders,
                                searchStr,
                                setSearchStr,
                                filterField,
                                setFilterFiled,
                                selectWidth: '160px'
                            }}
                        />

                        <Button
                            onClick={fetchOrders}
                            className=' bg-gradient-to-tr  from-gray-700 to-gray-400 text-white py-1.5 px-3 font-[300] rounded-lg'>
                            Filter
                        </Button>
                    </div>
                </div>

                <div className='block lg:hidden'>
                    <FilterModal
                        hooks={{
                            fetchOrders,
                            searchStr,
                            setSearchStr,
                            filterField,
                            setFilterFiled,
                            apiPayload,
                            handleDays,
                            handledate,
                            day
                        }}
                    />
                </div>
            </div>
            <>
                <div className='w-full mb-2'>
                    <CatalogueTable
                        hooks={{
                            loader,
                            handleRows,
                            handlePagination,
                            rows, page,
                            selectedRowDetails,
                            setSelectedRowDetails,
                            setImageData,
                            setImage_UniqueID,
                            totalRecords,
                            data: jewelleryOrders?.list
                        }}
                    />
                </div>
            </>
        </div>
    )
}

export default CatalogueLayout;