import { useState, React, useEffect } from 'react';
import { AlertError, AlertSuccess } from "src/Services"
import { useForm } from 'react-hook-form';
import { ADMIN_ADD_USER } from 'src/Services/Redux/Actions/admin_addUser'
import { useDispatch } from "react-redux";

export default function userCreation() {
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, reset, getValues, watch } = useForm();
    const [showPopup, setShowPopup] = useState(false);
    const [showUserCreation, setShowUserCreation] = useState(false);
    const [disableSalesGroup, setDisableSalesGroup] = useState(false);


    useEffect(() => {
        // Check local storage for the condition
        const isAdmin = localStorage.getItem('is_admin') === 'true';
        setShowUserCreation(isAdmin);
    }, []);

    const onSubmit = async (data) => {
        let res = await dispatch(ADMIN_ADD_USER(data))
        if (res?.data?.message == "User registered successfully.") {
            reset();
            AlertSuccess('User registered successfully.')
        }
        else {
            AlertError('User not registered')
        }
    };

    const handleSuperuserChange = (e) => {
        const isSuperuser = e.target.value === "1";
        const email = getValues("email");
        if (isSuperuser == '1' && email.endsWith("@ajaffe.com")) {
            setShowPopup(false);
        } else if (isSuperuser == '1' && !email.endsWith('@ajaffe.com')) {
            setShowPopup(true);
        }
    };

    const userType = watch('userType'); // Watch for changes in the userType field

    useEffect(() => {
        setDisableSalesGroup(userType === '3'); // Disable if userType is '3' (Admin)
    }, [userType]); // Re-run the effect whenever userType changes

    return (
        showUserCreation && (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <form onSubmit={handleSubmit(onSubmit)} className="max-w-100 mx-auto p-6 bg-white shadow-md rounded-2xl justify-center">
                    <div className="mb-4 flex">
                        <div className="w-1/2 mr-20">
                            <label
                                htmlFor="firstName"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                First Name :
                            </label>
                            <input
                                type="text"
                                id="firstName"
                                {...register("firstName",
                                    { required: "First name is required" })}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {errors.firstName && (
                                <p className="text-red-500 text-xs italic">

                                    {errors.firstName.message}
                                </p>
                            )}
                        </div>

                        <div className="w-1/2">
                            <label
                                htmlFor="lastName"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                Last Name :
                            </label>
                            <input
                                type="text"
                                id="lastName"
                                {...register("lastName")}

                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </div>

                    <div className='mb-4 flex'>
                        <div className="w-1/2 mr-20">
                            <label htmlFor="username" className="block text-gray-700 font-bold mb-2">
                                Username :
                            </label>
                            <input

                                type="text"
                                id="usename"
                                {...register("username")}

                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>

                        <div className="w-1/2">
                            <label htmlFor="contactPersonID" className="block text-gray-700 font-bold mb-2">
                                ContactPerson ID :
                            </label>
                            <input
                                type="text"
                                id="contactPersonID"
                                {...register("contactPersonID")}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>


                    </div>

                    <div className='mb-4 flex'>
                        <div className="w-1/2 mr-20">
                            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                                Email :
                            </label>
                            <input
                                type="email"
                                id="email"

                                {...register("email", {
                                    required: "Email is required", pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                            {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
                        </div>

                        <div className="w-1/2">
                            <label htmlFor="isSuperUser" className="block text-gray-700 font-bold mb-2">
                                Superuser Status :
                            </label>
                            <select
                                id="isSuperUser"
                                {...register("isSuperUser")}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={handleSuperuserChange}
                            >
                                <option value="">Select Superuser Status</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>

                    <div className='mb-4 flex'>
                        <div className="w-1/2 mr-20">
                            <label htmlFor="userType" className="block text-gray-700 font-bold mb-2">
                                User Type :
                            </label>
                            <select
                                id="userType"
                                {...register("userType")}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                            >
                                <option value="">Select User Type</option>
                                <option value="3">Admin</option>
                                <option value="1">Associate</option>
                                <option value="2">Manager</option>
                            </select>
                        </div>


                        <div className="w-1/2">
                            <label htmlFor="isStaff" className="block text-gray-700 font-bold mb-2">
                                Staff Status :
                            </label>
                            <select
                                id="isStaff"
                                {...register("isStaff")}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                            >
                                <option value="">Select Staff Status</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>

                    </div>

                    <div className="mb-4 flex">
                        <div className="w-1/2 mr-20">
                            <label
                                htmlFor="password"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                Password :
                            </label>
                            <input
                                type="text"
                                id="password"
                                {...register("password",
                                    { required: "First name is required" })}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {errors.firstName && (
                                <p className="text-red-500 text-xs italic">

                                    {errors.firstName.message}
                                </p>
                            )}
                        </div>

                        <div className="w-1/2">
                            <label htmlFor="isStaff" className="block text-gray-700 font-bold mb-2">
                                User Creation Rights Status :
                            </label>
                            <select
                                id="user_creation_rights"
                                {...register("user_creation_rights")}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                            >
                                <option value="">Select User Creation Rights</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="mb-4 flex">
                        <div className="w-1/2 mr-20">
                            <label
                                htmlFor="salesGroup"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                Sales Group :
                            </label>
                            <input
                                type="text"
                                id="salesGroup"
                                {...register("salesGroup")}
                                disabled={disableSalesGroup}
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${disableSalesGroup ? 'text-gray-500 bg-gray-200' : 'text-gray-700'}`}
                            />
                        </div>
                        <div className="w-1/2"></div>
                    </div>

                    <div className="flex items-center justify-between mt-10">
                        <button
                            className="bg-gray-900 bg-opacity-50 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit">
                            Submit
                        </button>
                    </div>

                    {showPopup && (
                        <div className="inset-0 flex items-center justify-center z-50 mt-8">
                            <div className="bg-white p-4 rounded shadow border">
                                <p>This user do not have ajaffe account. Are you sure to provide it superuser privileges.</p>
                                <button
                                    onClick={() => setShowPopup(false)}
                                    className="mt-2 bg-gray-900 bg-opacity-50 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"

                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        )
    );
}
