

// *************** USER INFO *********************

export const ACTION_LOGIN_USER = 'ACTION_LOGIN_USER'
export const ACTION_GET_ORDERS = 'ACTION_GET_ORDERS'
export const ACTION_GET_ORDERS_DETAIL_BY_ID = 'ACTION_GET_ORDERS_DETAIL_BY_ID'
export const ACTION_GET_VERSION = 'ACTION_GET_VERSION'
export const ACTION_GET_STYLE_VERSIONS = 'GET_STYLE_VERSIONS'
export const ACTION_GET_ATTACHMENT_DETAILS = 'GET_ATTACHMENT_DETAILS'
export const ACTION_GET_WEBORDERS = 'GET_WEBORDERS'
export const ACTION_GET_ARCHIVEORDERS = 'GET_ARCHIVEORDERS'
export const ACTION_GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const ACTION_HANLDE_CART_UPDATE = 'HANLDE_CART_UPDATE'
export const ACTION_GET_INVOICES = 'GET_INVOICES'
export const ACTION_GET_WEBORDER_DETAILS = 'GET_WEBORDER_DETAILS'
export const ACTION_GET_TOTAL_RETURNS = 'GET_TOTAL_RETURNS'
export const ACTION_CHATBOT_MESSAGES = 'ACTION_CHATBOT_MESSAGES'
export const ACTION_CHAT_API_CALL_TRUE = 'ACTION_CHAT_API_CALL_TRUE'
export const ACTION_AJAFFE_JEWELLERY_DATA = 'ACTION_AJAFFE_JEWELLERY_DATA'